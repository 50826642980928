import React, { useState, useEffect, useRef } from "react";
import Card from "../../components/Card/Card";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch";
import SwitchButton from "../../components/SwitchButton/SwitchButton";
import Layout from "../../components/Layout";
import Pagination from "../../components/Common/Pagination";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import {
  getArchitectDetails,
  filterService,
  setFilters,
  resetCurrentPage,
} from "../../reducers/Services/actions";
import { getCities, setCity, getDistricts, setDistrict, setServiceType, generateNewGuid } from "../../reducers/Home/actions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { ChipSet, Chip } from "@rmwc/chip";
import { scrollToElement } from '../../utils/util';
import { navigate } from "gatsby-link";
import architectImage from '../../assets/images/architect-default-image.jpg';
import builderImage from '../../assets/images/builder-default-image.jpg';
import distributorImage from '../../assets/images/dealer-default-image.jpg';
import { urlDescription } from '../../utils/pageDescription'
import "@rmwc/chip/styles";
import "./styles.scss";

const Services = ({
  page,
  services,
  filters,
  setFilters,
  resetCurrentPage,
  serviceType,
  selectedFilters,
  setServiceType,
  filterService,
  city,
  setCity,
  setDistrict,
  districtId,
  totalPages,
  getCities,
  getDistricts,
  guid,
  generateNewGuid
}) => {

  const [selected, setSelected] = useState([]);
  const titleRef = useRef(null)
  const [place, setPlace] = useState(null)

  let url = "";
  let service = ''
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    service = url.searchParams.get("service");
  }

  useEffect(() => {
    filterService({
      page,
      cityId: city && city.id,
      districtId: districtId && districtId.id,
      service,
      filters: selected.length > 0 ? selected : null,
      guid
    });
  }, [filterService, page, districtId, service, guid, city, selected, selected.length])


  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  const selectFilter = (key) => {
    let newArr = [...selected];
    if (key === 0) {
      if (returnFiltersIds(filters).length === newArr.length) {
        newArr = []
        setFilters(newArr)
        setSelected(newArr)
      } else {
        newArr = returnFiltersIds(filters).slice(0)
        setFilters(newArr)
        setSelected(newArr)
        return
      }
      return
    }
    if (returnFiltersIds(filters).length === newArr.length) {
      newArr = []
      setFilters(newArr)
      setSelected(newArr)
    }
    if (newArr.includes(key)) {
      let index = newArr.indexOf(key);
      newArr.splice(index, 1);
    } else {
      newArr.push(key);
    }
    setFilters(newArr)
    return setSelected(newArr);
  };

  const returnFiltersIds = (filters) => {
    return filters.map((f) => f.id);
  };

  const setServiceInfo = (service) => {
    switch (service) {
      case "architects":
        return "Архитектурно бюро";
      case "builders":
        return "Строителна компания";
      case "dealers":
        return "Дистрибутори";

      default:
        return "";
    }
  };

  const handlePageClick = (data) => {
    filterService({
      page: data.selected + 1,
      cityId: city && city.id,
      districtId: districtId && districtId.id,
      service: service,
      filters: selected.length > 0 ? selected : null,
      guid
    });
  };

  const returnDefaultImage = (service) => {
    switch (service) {
      case "architects":
        return architectImage;
      case "builders":
        return builderImage;
      case "dealers":
        return distributorImage;

      default:
        break;
    }
  }

  const returnBuildersFilters = (filters) => {
    let newFilters = [...filters]
    let index = newFilters.findIndex(f => f.name === 'Груб строеж')
    let filter = newFilters.filter(f => f.name === 'Груб строеж')
    newFilters.splice(index, 1)
    newFilters.splice(1, 0, filter[0])
    return newFilters
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="services-page-wrapper">
        <div className="services-page-container">
          <Row>
            <Col className="my-5 centered-text">
              <h1 className="h2 font-weight-bold" ref={titleRef}>Услуги</h1>
              {city && (
                <p className="mb-5 mt-2">Резултати: община {city.label}</p>
              )}
              {districtId && (
                <p className="mt-2">
                  Резултати: област {districtId.label || districtId.name}
                </p>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              lg={4}
            >
              <div className="services-tab-col margin-top-2 margin-bottom-1-5 border px-4">
                <div className="services-criteria-tab-container">
                  <h6 className="mb-3 font-weight-bold mb-3 mt-4">Избери критерий</h6>
                  <h6 className="mb-0">Местонахождение</h6>
                  <div className='services-dropdowns'>
                    <DropdownSearch
                      selectClassName="react-select-container react-select__control"
                      image={<GpsFixedIcon />}
                      text={"Въведете град или ПК"}
                      value={city 
                        ? city.label 
                        : districtId
                          ? districtId.label
                          : ''
                      }
                      onInputChange={e => setPlace(e)}
                      onChange={(e) => {
                        if(e && Object.keys(e).indexOf('district_id') >= 0) {
                          resetCurrentPage()
                          setCity(null);
                          setDistrict(e)
                          return
                        }
                        resetCurrentPage()
                        setCity(e);
                        setDistrict(null);
                      }}
                    />
                    <hr className="margin-left-2 services-hr" />
                    <SwitchButton
                      switchSize={"h-72"}
                      title="Архитектурно бюро"
                      checked={service === "architects"}
                      onClick={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        navigate(`/services?service=architects`)
                        resetCurrentPage()
                        setServiceType({ type: "architects" })
                      }}
                      onChange={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        resetCurrentPage();
                      }}
                    />
                    <SwitchButton
                      switchSize={"h-72"}
                      title="Строителна компания"
                      checked={service === 'builders'}
                      onClick={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        navigate(`/services?service=builders`)
                        resetCurrentPage();
                        setServiceType({ type: "builders" })
                      }}
                      onChange={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        resetCurrentPage();
                      }}
                    />
                    <SwitchButton
                      switchSize={"h-72"}
                      title="Дистрибутори"
                      checked={service === 'dealers'}
                      onClick={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        navigate(`/services?service=dealers`)
                        resetCurrentPage();
                        setServiceType({ type: "dealers" })
                      }}
                      onChange={() => {
                        generateNewGuid()
                        setSelected([])
                        setFilters([])
                        resetCurrentPage();
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={8}
            >
              <div className="margin-top-2 margin-bottom-1-5 d-flex flex-column justify-content-between">
                <div className="filter-tab">
                  <ChipSet filter>
                    <p className="filter-tab-p">Филтър:</p>
                    <Chip
                      selected={selected.length === returnFiltersIds(filters).length}
                      checkmark
                      onInteraction={() => {
                        generateNewGuid()
                        // filterService({
                        //   page,
                        //   service: serviceType,
                        //   filters: returnFiltersIds(filters),
                        // });
                        selectFilter(0);
                      }}
                      label="Всички"
                    />
                    {
                      filters && service !== 'builders'
                        ? filters.map((filter) => {
                          return (
                            <Chip
                              key={filter.id}
                              selected={selectedFilters.indexOf(filter.id) >= 0}
                              checkmark
                              onInteraction={() => {
                                generateNewGuid()
                                // filterService({
                                //   page,
                                //   service: serviceType,
                                //   filters: selected,
                                // });
                                selectFilter(filter.id);
                              }}
                              label={filter.name}
                            />
                          );
                        })
                        : returnBuildersFilters(filters).map((filter, i) => {
                          return (
                            <Chip
                              key={i}
                              selected={filter && selectedFilters.indexOf(filter.id) >= 0}
                              checkmark
                              onInteraction={() => {
                                generateNewGuid()
                                // filterService({
                                //   guid: uuidv4(),
                                //   page,
                                //   service: serviceType,
                                //   filters: selected,
                                // });
                                selectFilter(filter.id);
                              }}
                              label={filter && filter.name}
                            />
                          );
                        })
                    }
                  </ChipSet>
                </div>
              </div>
              {services && services.length > 0 
                ? services.map((item) => {
                  return (
                    <Row key={item.id} className="services-card-row">
                      <Col xs={12} className="pb-4">
                        <Card
                          url={`/service-details?id=${item.id}&service=${service}`}
                          cardClass="services-card"
                          imageClass="services-card-img"
                          cardBodyClass="services-card-body"
                          btnClass="services-card-btn"
                          serviceInfo={setServiceInfo(service)}
                          title={item.title}
                          desc={`${item.description ? `${item.description.substr(0, 140)}...` : ''}`}
                          image={
                            item.image
                              ? `${process.env.GATSBY_XELLA_BACKEND}${item.image}`
                              : returnDefaultImage(service)
                          }
                          onClick={() => { }}
                        />
                      </Col>
                    </Row>
                  );
                })
                : <div className='font-weight-bold d-flex justify-content-center'>Няма намерени резултати</div>
              }
              <div className="mt-5 d-flex justify-content-center">
                {
                  totalPages && totalPages > 1 
                  ? <Pagination
                      handlePageClick={(data) => {
                        handlePageClick(data);
                        scrollToElement(titleRef)
                      }}
                      pageCount={totalPages}
                      currentPage={page}
                    />
                  : null
                }
              </div>

            </Col>
          </Row>

        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  services: state.services.data,
  filters: state.services.filters,
  selectedFilters: state.services.selectedFilters,
  details: state.services.details,
  serviceType: state.home.serviceType,
  city: state.home.city,
  districtId: state.home.districtId,
  page: state.services.current_page,
  totalPages: state.services.total_pages,
  guid: state.home.guid
});

const mapDispatchToProps = {
  getCities,
  setCity,
  getDistricts,
  setDistrict,
  getArchitectDetails,
  setServiceType,
  filterService,
  setFilters,
  resetCurrentPage,
  generateNewGuid
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
