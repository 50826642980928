import React from 'react';
import { Input } from 'reactstrap';
import './styles.scss';

const SwitchButton = (props) => {
  const { switchSize, title, checked, onChange, onClick } = props;

  return (
    <>
      <div
        role="none"
        // tabIndex={0}
        className={switchSize ? `${switchSize} cursor-pointer d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4` : 'd-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4'}
        onClick={onClick}
      >
        <p className="d-inline mb-0">{title}</p>
        <Input
          type="radio"
          name="radio1"
          checked={checked}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default SwitchButton;